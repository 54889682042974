<template>
    <div id="contenedor">
        <div id="header">
            <router-link to="/">
                <img src="../assets/logo_entretantocuento.png" alt="#EntreTantoCuento" id="header_logo">
            </router-link>
            <img @click="togglemenu" id="icono_menu" src="../assets/icono_menu.svg">
            <ul id="header_enlaces">
                <li><router-link to="/">Inicio</router-link></li>
                <li><router-link to="/formate">Fórmate</router-link></li>
                <li><router-link to="/pruebate">Pruébate</router-link></li>
                <li><router-link to="/difunde">Difunde</router-link></li>
                <li><router-link to="/comparte">Comparte</router-link></li>
                <li><router-link to="/blog">Blog</router-link></li>
            </ul>
        </div>

        <router-view :key="$route.path" />

        <div id="footer">
            <img id="footer_skyline" src="../assets/patron_ciudad.png">
            <div class="footer_text">
                &copy; Fundación Telefónica Movistar, Av. Suba #114A-55 Bogotá Colombia. Todos los derechos reservados.
            </div>
            <img id="footer_sponsors" src="../assets/logo_patrocinadores.png">
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    togglemenu: function () {
      document.querySelector('#header_enlaces').style.display = 'flex'
      document.querySelector('#icono_menu').style.display = 'none'
    }
  }
}
</script>

<style scoped>
#header {
    background-color: white;
    border-style: none solid solid solid;
    border-width: 3px;
    border-color: #0C1D3E;
    border-radius: 0px 0px 15px 15px;

    padding: 0px 70px;
    display: flex;
    align-items: center;
}
#header_logo {
    width: 150px;
    padding: 15px;
}
#icono_menu {
    display: none;
    margin-left: auto;
    margin-right: 20px;
}
#icono_menu:hover {
    cursor: pointer;
}
#header_enlaces {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-left: auto;
    font-size: 24px;
}
#header_enlaces li {
    margin: 6px 25px;
}
#header_enlaces a:link, a:visited {
    text-decoration: none;
    color: #0C1D3E;
}
#header_enlaces a:hover {
    text-decoration: none;
    color: #54ceea;
}
#header_enlaces a.router-link-exact-active {
    color: #54ceea;
    border-width: 3px;
    border-style: none none solid;
}

#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0C1D3E;
    margin-top: 200px;
}
#footer_skyline {
    object-fit: cover;
    width: 100%;
    height: 250px;
    margin-top: -200px;
    margin-bottom: -50px;
}
.footer_text {
    color: grey;
    font-size: 20px;
    font-family: 'Helvetica';
    text-align: center;
    padding: 25px;
    width: 70%;
}
#footer_sponsors {
    width: 40%;
}

@media screen and (max-width: 820px) {
    #header {
        padding: 10px;
    }
    #header_logo {
        width: 120px;
        padding: 15px;
    }
    #icono_menu {
        display: block;
    }
    #header_enlaces {
        display: none;
    }
    .footer_text {
        font-size: 12px;
    }
    #footer_sponsors {
        width: 90%;
    }
}
</style>
